<template>
  <CDropdown
    inNav
    class="c-header-nav-items"
    placement="bottom-end"
    add-menu-classes="pt-0"
  >
    <template #toggler>
      <CHeaderNavLink>
        Opciones <font-awesome-icon icon="chevron-down" style="margin-left: 5px;"/>
      </CHeaderNavLink>
    </template>
    <CDropdownHeader tag="div" class="text-center text-white" color="primary">
      <strong>Opciones</strong>
    </CDropdownHeader>
    <CDropdownItem @click="toggleSearchUserModal">
      <font-awesome-icon icon="user" style="margin-right: 10px;"/> Buscar Usuarios
    </CDropdownItem>
    <CDropdownItem @click="toggleModalAudio">
      <font-awesome-icon icon="microphone" style="margin-right: 10px;"/> Ajustes de Audio
    </CDropdownItem>
    <div class="d-flex align-items-center item-drop-custom">
      <CSwitch
        class="mr-2"
        size="sm"
        color="primary"
        shape = "pill"
        @update:checked="toggleAlarmSound"
        :checked="$store.state.alarmSound"
      />
      <div>
        <p class="mb-0 mr-2 text-muted" style="font-size: 10px;"><strong>Sonido Alertas</strong></p>
      </div>
    </div>
    <div class="d-flex align-items-center item-drop-custom">
      <CSwitch
        class="mr-2"
        size="sm"
        color="primary"
        shape = "pill"
        :checked.sync="$store.state.showVideo"
      />
      <div>
        <p class="mb-0 mr-2 text-muted" style="font-size: 10px;"><strong>{{$store.state.showVideo ? 'Atención Primaria' : 'Seguridad'}}</strong></p>
        <p class="mb-0 mr-2 text-muted" style="font-size: 10px;">{{$store.state.showVideo ? 'Videoconferencia Automática' : 'Monitoreo Silencioso'}}</p>
      </div>
    </div>
    <div class="d-flex align-items-center item-drop-custom">
      <CSwitch
        class="mr-2"
        size="sm"
        color="primary"
        shape = "pill"
        :checked.sync="$store.state.enabledLimitMarkers"
      />
      <div>
        <p class="mb-0 mr-2 text-muted" style="font-size: 10px;"><strong>Limitar Cantidad Marcadores</strong></p>
        <CInput v-model="limitMarkers"  v-if="$store.state.enabledLimitMarkers" style="height: 20px; margin-bottom: 4px;"/>
      </div>
    </div>
  </CDropdown>
</template>

<script>
import * as firebase from 'firebase/app'
import 'firebase/database'

export default {
  name: 'OptionsDropdown',
  data () {
    return {
      alertas: [],
      animated: false,
      limitMarkers: 200
    }
  },
  mounted(){
    const limitMarkers = localStorage.getItem("limitGeoUsersMarkers")
    if (limitMarkers) {
      this.limitMarkers = limitMarkers
      this.$store.commit('setEnabledLimitMarkers', true)
    } else {
      this.$store.commit('setEnabledLimitMarkers', false)
    }
  },
  methods: {
    toggleAlarmSound(){
      this.$store.commit('setAlarmSound', !this.$store.state.alarmSound)
    },
    toggleSearchUserModal(){      
      this.$store.commit('setShowUserModalSearch', !this.$store.state.showUserModalSearch)
    },
        toggleModalAudio() {
      this.$store.commit('setModalAudioSettings', !this.$store.state.modalAudioSettings)
    }
  },
  watch: {
    limitMarkers(value) {
      this.$store.commit('setLimitMarkers', value)
      localStorage.setItem("limitGeoUsersMarkers", value);
    },
    '$store.state.enabledLimitMarkers': function(value) {
      if (value) {
        localStorage.setItem("limitGeoUsersMarkers", this.limitMarkers);
      } else {
        localStorage.removeItem("limitGeoUsersMarkers");
      }
    },
  }
}
</script>
