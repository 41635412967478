import { render, staticRenderFns } from "./AlertasDropdown.vue?vue&type=template&id=275fdffc&scoped=true"
import script from "./AlertasDropdown.vue?vue&type=script&lang=js"
export * from "./AlertasDropdown.vue?vue&type=script&lang=js"
import style0 from "./AlertasDropdown.vue?vue&type=style&index=0&id=275fdffc&prod&scoped=true&lang=css"
import style1 from "./AlertasDropdown.vue?vue&type=style&index=1&id=275fdffc&prod&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "275fdffc",
  null
  
)

export default component.exports