<template>
  <CHeader fixed colorScheme="dark" class="bg-primary">
    <CToggler
      in-header
      class="ml-3 d-lg-none"
      v-c-emit-root-event:toggle-sidebar-mobile
    />
    <CToggler
      in-header
      class="ml-3 d-md-down-none"
      v-c-emit-root-event:toggle-sidebar
    />
    <CHeaderBrand
      :src="require('../assets/imgs/logo-iduam.png')"
      style="object-fit: contain; max-width: 100px"
      alt="Iduam"
    />
    <CHeaderNav class="mr-auto">
<!--       <CHeaderNavItem class="px-3">
        <CHeaderNavLink to="/dashboard">
          Dashboard
        </CHeaderNavLink>
      </CHeaderNavItem>
      <CHeaderNavItem class="px-3">
        <CHeaderNavLink to="/users" exact>
          Users
        </CHeaderNavLink>
      </CHeaderNavItem>
      <CHeaderNavItem class="px-3">
        <CHeaderNavLink>
          Settings
        </CHeaderNavLink>
      </CHeaderNavItem> -->
      <CDropdown
        color="primary"
        toggler-text="Segmentación" 
        class="m-2 dropclusters-header"
      >
        <CDropdownItem @click="filter.mujeres = !filter.mujeres">
          <div class="d-flex align-items-center justify-content-center">
            <CInputCheckbox :checked="filter.mujeres" style="height: 20px;"/> <p class="mb-0">Mujeres</p>
          </div>
        </CDropdownItem>
        <CDropdownItem @click="filter.comercio = !filter.comercio">
          <div class="d-flex align-items-center justify-content-center">
            <CInputCheckbox :checked="filter.comercio" style="height: 20px;"/> <p class="mb-0">Comercio</p>
          </div>
        </CDropdownItem>
        <CDropdownItem @click="filter.hogar = !filter.hogar">
          <div class="d-flex align-items-center justify-content-center">
            <CInputCheckbox :checked="filter.hogar" style="height: 20px;"/> <p class="mb-0">Hogar</p>
          </div>
        </CDropdownItem>
        <div class="mx-1">
          <CButton
            color="primary"
            size="sm"
            class="w-100"
            @click="$events.fire('filterSegments', filter)"
          >
            Aplicar
          </CButton>
        </div>
      </CDropdown>
      <CButton @click="showGeneral()" class="mx-1" color="success" size="sm" :variant="$store.state.dashboardMap == 'general' ? '':'outline'">
        General
      </CButton>
      <CButton @click="showSmartbands()" class="mx-1" color="success" size="sm" :variant="$store.state.dashboardMap == 'smartbands' ? '':'outline'">
        Smartbands
      </CButton>
      <CButton @click="showFeedback()" class="mx-1" color="success" size="sm" :variant="$store.state.dashboardMap == 'feedback' ? '':'outline'">
        Feedback
      </CButton>

    </CHeaderNav>
    <CHeaderNav class="mr-4">
      <!-- <CHeaderNavItem class="d-md-down-none mx-2">
        <CHeaderNavLink>
          <CIcon name="cil-bell"/>
        </CHeaderNavLink>
      </CHeaderNavItem>
      <CHeaderNavItem class="d-md-down-none mx-2">
        <CHeaderNavLink>
          <CIcon name="cil-list"/>
        </CHeaderNavLink>
      </CHeaderNavItem>
      <CHeaderNavItem class="d-md-down-none mx-2">
        <CHeaderNavLink>
          <CIcon name="cil-envelope-open"/>
        </CHeaderNavLink> -->
      </CHeaderNavItem>
<!--       <div class="d-flex align-items-center">
        <div>
          <p class="mb-0 mr-2 text-muted" style="font-size: 10px;"><strong>Sonido Alertas</strong></p>
        </div>
        <CSwitch
          class="mr-2"
          size="sm"
          color="primary"
          shape = "pill"
          @update:checked="toggleAlarmSound"
          :checked="$store.state.alarmSound"
        />
      </div>
      <div class="d-flex align-items-center">
        <div>
          <p class="mb-0 mr-2 text-muted" style="font-size: 10px;"><strong>{{$store.state.showVideo ? 'Atención Primaria' : 'Seguridad'}}</strong></p>
          <p class="mb-0 mr-2 text-muted" style="font-size: 10px;">{{$store.state.showVideo ? 'Videoconferencia Automática' : 'Monitoreo Silencioso'}}</p>
        </div>
        <CSwitch
          class="mr-2"
          size="sm"
          color="primary"
          shape = "pill"
          :checked.sync="$store.state.showVideo"
        />
      </div> -->
      <OptionsDropdown />
      <div @click="openModalUnsavedNotes" v-if="notesCount">
        <CHeaderNavLink class="tada" :class="true > 0 ? 'animated infinite':null">
          <CIcon size="xl" name="cil-layers"/>
          <CBadge color="primary" class="ml-auto">{{notesCount}}</CBadge>
        </CHeaderNavLink>
      </div>
      <AlertasDropdown />
      <!-- <TheHeaderDropdownAccnt/> -->
      <CButton @click="toogleGrilla()" color="primary" size="sm" :variant="$store.state.grillaMonitoreo ? '':'outline'">
        <img class="white-color-image-overlay" style="margin-bottom: 2px;max-height: 20px; max-width: 20px;" :src="require('../assets/imgicons/ICONOS SOSRecurso 10@2x.png')"/>
        <CBadge color="primary" class="ml-auto mr-2" style="position: absolute;top: 50%;margin-top: -16px;">{{$store.state.monitoreoGrillaCounter}}</CBadge>
      </CButton>
    </CHeaderNav>
<!--     <CSubheader class="px-3">
      <CBreadcrumbRouter class="border-0"/>
    </CSubheader> -->
  </CHeader>
</template>

<script>
import TheHeaderDropdownAccnt from './TheHeaderDropdownAccnt'
import AlertasDropdown from './AlertasDropdown'
import OptionsDropdown from './OptionsDropdown'

export default {
  name: 'TheHeader',
  components: {
    TheHeaderDropdownAccnt,
    AlertasDropdown,
    OptionsDropdown
  },
  data(){
    return {
      filter: {
        mujeres: true,
        comercio: true,
        hogar: true
      },
      notesCount: null
    }
  },
  methods:{
    openModalUnsavedNotes() {
      this.$store.commit('setOpenModalUnsavedNotes', true)
    },
    tooglePanel(){
      this.$store.commit('setPanelShow', !this.$store.state.monitorPanelShow)
    },
    showGeneral() {
      this.$store.commit('setDashboardMap', 'general')
    },
    showSmartbands() {
      this.$store.commit('setDashboardMap', 'smartbands')
    },
    showFeedback() {
      this.$store.commit('setDashboardMap', 'feedback')
    },
    toggleBotones(){
      this.$store.commit('setShowBotones', !this.$store.state.showBotones)
    },
    toggleAlarmSound(){
      this.$store.commit('setAlarmSound', !this.$store.state.alarmSound)
    },
    toogleGrilla(){
      this.$store.commit('toogleGrilla', !this.$store.state.grillaMonitoreo)
    },
  },
  watch: {
    '$store.state.openModalUnsavedNotes'() {
      this.notesCount = JSON.parse(localStorage.getItem("notes")).length;
    }
  },
  mounted () {
    this.notesCount = JSON.parse(localStorage.getItem("notes")).length;
  },
}
</script>