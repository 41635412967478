<template>
  <CSidebar 
    colorScheme="light"
    fixed 
    :minimize="minimize"
    :show.sync="show"
  >
    <button @click="show = false" class="close-btn">
        <img src='../assets/icons/svg/Close.svg' class="close-icon">
      </button>
    <CSidebarNav>
      <CSidebarNavItem
        name='Mapa'
        to='/dashboard'
        :icon="{
          class: 'menu-icons-img',
          src: require('../assets/imgicons/ICONOS SOSRecurso 11@2x.png')
        }"/>
      <CSidebarNavItem
        name='Alertas'
        to='/alertas'
        :icon="{
            class: 'menu-icons-img',
            src: require('../assets/imgicons/ICONOS SOSRecurso 12@2x.png')
          }"/>
      <CSidebarNavItem
        name='Formularios PreRegistro'
        to='/forms-preregistro'
        :icon="{
            class: 'menu-icons-img',
            src: require('../assets/imgicons/ICONOS SOSRecurso 22@2x.png')
          }"/>
      <CSidebarNavItem
        v-if="checkPermissions(['view_cuadrantes'])"
        name='Cuadrantes'
        to='/cuadrantes'
        :icon="{
          class: 'menu-icons-img',
          src: require('../assets/imgicons/ICONOS SOSRecurso 30@2x.png')
        }"/>
      <CSidebarNavItem
        v-if="checkPermissions(['edit_operational_groups'])"
        name='Grupos Operacionales'
        to='/grupos-operacionales'
        :icon="{
          class: 'menu-icons-img',
          src: require('../assets/imgicons/ICONOS SOSRecurso 30@2x.png')
        }"/>
      <CSidebarNavItem
        v-if="checkPermissions(['view_user_app'])"
        name='Usuarios'
        to='/users'
        :icon="{
          class: 'menu-icons-img',
          src: require('../assets/imgicons/ICONOS SOSRecurso 15@2x.png')
        }"/>
      <CSidebarNavItem
        v-if="checkPermissions(['view_user_operator'])"
        name='Usuarios Operadores'
        to='/operators-users'
        :icon="{
          class: 'menu-icons-img',
          src: require('../assets/imgicons/ICONOS SOSRecurso 15@2x.png')
        }"/>
      <CSidebarNavItem
        v-if="checkPermissions(['view_user_admin'])"
        name='Usuarios Admin'
        to='/admin-users'
        :icon="{
          class: 'menu-icons-img',
          src: require('../assets/imgicons/ICONOS SOSRecurso 15@2x.png')
        }"/>
<!--       <CSidebarNavItem
        v-if="checkPermissions(['view_publicidad'])"
        name='Publicidad'
        to='/publicidad'
        :icon="{
          class: 'menu-icons-img',
          src: require('../assets/imgicons/ICONOS SOSRecurso 16@2x.png')
        }"/> -->

        <CSidebarNavDropdown
        name="Atenciones"
        :icon="{
          class: 'menu-icons-img',
          src: require('../assets/imgicons/ICONOS SOSRecurso 21-2@2x.png')
        }">
        <CSidebarNavItem
          name='Atenciones'
          to='/atenciones-general'
          :icon="{
            class: 'menu-icons-img',
            src: require('../assets/imgicons/ICONOS SOSRecurso 21-2@2x.png')
          }"/>
        <CSidebarNavItem
          name='Solicitudes de Atención'
          to='/solicitudes-atencion'
          :icon="{
            class: 'menu-icons-img',
            src: require('../assets/imgicons/ICONOS SOSRecurso 21-2@2x.png')
          }"/>
      </CSidebarNavDropdown>
      <CSidebarNavItem
        name='Citas'
        to='/citas'
        :icon="{
          class: 'menu-icons-img',
          src: require('../assets/imgicons/ICONOS SOSRecurso 21-2@2x.png')
        }"/>
      <CSidebarNavItem
        name='Horarios'
        to='/horarios'
        :icon="{
          class: 'menu-icons-img',
          src: require('../assets/imgicons/ICONOS SOSRecurso 21-2@2x.png')
        }"/>
      <CSidebarNavItem
        v-if="checkPermissions(['view_user_app'])"
        name='Configuraciones'
        to='/configuracion'
        :icon="{
          class: 'menu-icons-img',
          src: require('../assets/icons/svg/settingsblack.svg'),
        }"/>
      <CSidebarNavDivider class="my-2"/>
      <div @click="logOut()">
      <CSidebarNavItem
        name='Salir'
        addLinkClasses="logout-click-handler"
        @click="logOut()"
        to='/login'
        :icon="{
          class: 'menu-icons-img',
          src: require('../assets/imgicons/ICONOS SOSRecurso 29@2x.png')
        }"/>
      </div>
    </CSidebarNav>
<!--     <CSidebarBrand 
      :imgFull="{ width: 118, alt: 'Logo', src: require('../assets/imgs/logo-iduam.png')}"
      :imgMinimized="{ width: 60, alt: 'Logo', src: require('../assets/imgs/logo-iduam.png')}"
      :wrappedInLink="{ href: '/', target: '_blank'}"
    /> -->
    <!-- <CRenderFunction flat :content-to-render="nav"/> -->
<!--     <CSidebarMinimizer
      class="d-md-down-none"
      @click.native="minimize = !minimize"
    /> -->
  </CSidebar>
</template>

<script>
import nav from './_nav'
import checkPermissions from '../helpers/userPermissions'

export default {
  name: 'TheSidebar',
  data () {
    return {
      minimize: false,
      nav,
      show: false
    }
  },
  watch:{
    $route (to, from){
      this.show = false
    }
  },
  mounted () {
    this.$root.$on('toggle-sidebar', () => {
      const sidebarOpened = this.show === true || this.show === 'responsive'
      this.show = sidebarOpened ? false : 'responsive'
    })
    this.$root.$on('toggle-sidebar-mobile', () => {
      const sidebarClosed = this.show === 'responsive' || this.show === false
      this.show = sidebarClosed ? true : 'responsive'
    })
  },
  methods:{
    checkPermissions,
    async logOut(){
      console.log("Salir?")
      await localStorage.removeItem('token')
      this.$router.push('Login')
    }
  }
}
</script>
<style lang="scss">
    .close-btn {
    border: none;
    display: flex;
  }
.close-btn:focus {outline:0;}
  .close-icon {
    margin-left: auto;
    width: 30%;  
    max-width: 40px;
    margin-top: 10px;
    margin-bottom: 10px;
  }
</style>