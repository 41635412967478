var render = function render(){var _vm=this,_c=_vm._self._c;return _c('CSidebar',{attrs:{"colorScheme":"light","fixed":"","minimize":_vm.minimize,"show":_vm.show},on:{"update:show":function($event){_vm.show=$event}}},[_c('button',{staticClass:"close-btn",on:{"click":function($event){_vm.show = false}}},[_c('img',{staticClass:"close-icon",attrs:{"src":require("../assets/icons/svg/Close.svg")}})]),_c('CSidebarNav',[_c('CSidebarNavItem',{attrs:{"name":"Mapa","to":"/dashboard","icon":{
          class: 'menu-icons-img',
          src: require('../assets/imgicons/ICONOS SOSRecurso 11@2x.png')
        }}}),_c('CSidebarNavItem',{attrs:{"name":"Alertas","to":"/alertas","icon":{
            class: 'menu-icons-img',
            src: require('../assets/imgicons/ICONOS SOSRecurso 12@2x.png')
          }}}),_c('CSidebarNavItem',{attrs:{"name":"Formularios PreRegistro","to":"/forms-preregistro","icon":{
            class: 'menu-icons-img',
            src: require('../assets/imgicons/ICONOS SOSRecurso 22@2x.png')
          }}}),(_vm.checkPermissions(['view_cuadrantes']))?_c('CSidebarNavItem',{attrs:{"name":"Cuadrantes","to":"/cuadrantes","icon":{
          class: 'menu-icons-img',
          src: require('../assets/imgicons/ICONOS SOSRecurso 30@2x.png')
        }}}):_vm._e(),(_vm.checkPermissions(['edit_operational_groups']))?_c('CSidebarNavItem',{attrs:{"name":"Grupos Operacionales","to":"/grupos-operacionales","icon":{
          class: 'menu-icons-img',
          src: require('../assets/imgicons/ICONOS SOSRecurso 30@2x.png')
        }}}):_vm._e(),(_vm.checkPermissions(['view_user_app']))?_c('CSidebarNavItem',{attrs:{"name":"Usuarios","to":"/users","icon":{
          class: 'menu-icons-img',
          src: require('../assets/imgicons/ICONOS SOSRecurso 15@2x.png')
        }}}):_vm._e(),(_vm.checkPermissions(['view_user_operator']))?_c('CSidebarNavItem',{attrs:{"name":"Usuarios Operadores","to":"/operators-users","icon":{
          class: 'menu-icons-img',
          src: require('../assets/imgicons/ICONOS SOSRecurso 15@2x.png')
        }}}):_vm._e(),(_vm.checkPermissions(['view_user_admin']))?_c('CSidebarNavItem',{attrs:{"name":"Usuarios Admin","to":"/admin-users","icon":{
          class: 'menu-icons-img',
          src: require('../assets/imgicons/ICONOS SOSRecurso 15@2x.png')
        }}}):_vm._e(),_c('CSidebarNavDropdown',{attrs:{"name":"Atenciones","icon":{
          class: 'menu-icons-img',
          src: require('../assets/imgicons/ICONOS SOSRecurso 21-2@2x.png')
        }}},[_c('CSidebarNavItem',{attrs:{"name":"Atenciones","to":"/atenciones-general","icon":{
            class: 'menu-icons-img',
            src: require('../assets/imgicons/ICONOS SOSRecurso 21-2@2x.png')
          }}}),_c('CSidebarNavItem',{attrs:{"name":"Solicitudes de Atención","to":"/solicitudes-atencion","icon":{
            class: 'menu-icons-img',
            src: require('../assets/imgicons/ICONOS SOSRecurso 21-2@2x.png')
          }}})],1),_c('CSidebarNavItem',{attrs:{"name":"Citas","to":"/citas","icon":{
          class: 'menu-icons-img',
          src: require('../assets/imgicons/ICONOS SOSRecurso 21-2@2x.png')
        }}}),_c('CSidebarNavItem',{attrs:{"name":"Horarios","to":"/horarios","icon":{
          class: 'menu-icons-img',
          src: require('../assets/imgicons/ICONOS SOSRecurso 21-2@2x.png')
        }}}),(_vm.checkPermissions(['view_user_app']))?_c('CSidebarNavItem',{attrs:{"name":"Configuraciones","to":"/configuracion","icon":{
          class: 'menu-icons-img',
          src: require('../assets/icons/svg/settingsblack.svg'),
        }}}):_vm._e(),_c('CSidebarNavDivider',{staticClass:"my-2"}),_c('div',{on:{"click":function($event){return _vm.logOut()}}},[_c('CSidebarNavItem',{attrs:{"name":"Salir","addLinkClasses":"logout-click-handler","to":"/login","icon":{
          class: 'menu-icons-img',
          src: require('../assets/imgicons/ICONOS SOSRecurso 29@2x.png')
        }},on:{"click":function($event){return _vm.logOut()}}})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }